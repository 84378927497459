<template>
	<div>
		<div class="headimg"></div>
		<div class="userinfobox">
			<div class="infocenter">
				<div class="userheadimg">
					<img :src="imgbaseUrl + userInfo.avatar" alt="" class="point" @click="updataclick" />
					<div>{{ userInfo.email }}</div>
				</div>
				<div class="changeinfobox">
					<div class="btnbox b1" @click="passwordShow = !passwordShow">Modify PassWord</div>
					<div class="btnbox b1" @click="changeemailShow = !changeemailShow">Modify Email</div>
					<div @click="loginOutOnClick" class="btnbox b2">Log out</div>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="center">
				<div class="tipsbox">
					<div class="tipsicon">
						<img src="../../assets/images/pc-user-tipsicon.png" alt="" />
					</div>
					<div class="tipstext">Hint：To fully display all purchased items in each order, please click the button to the far right of the first item.</div>
				</div>
				<div class="listbox">
					<div class="listhead">
						<div class="headtext">Order Details</div>
						<div class="searchbox">
							<div class="select-box point" @click="isShowFloat = !isShowFloat">
								<div class="input-value flex-s">
									{{ parseSelectList.find((item) => item.value == value)?.label }}
								</div>
								<div class="row">
									<img src="@/assets/images/pc-user-pullicon.png" alt="" />
								</div>
								<div v-show="isShowFloat" class="float-box1">
									<div @click="changeSelect(item)" class="float-box-item flex-s" v-for="(item, index) in parseSelectList" :key="index">
										{{ item.label }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="table-box">
						<div class="table-header">
							<div style="flex-grow: 1">Product name</div>
							<div>Purchase quantity</div>
							<div>Unit price</div>
							<div style="flex-grow: 1">Total</div>
						</div>
						<div class="table-body">
							<div v-for="(item, index) in orderlist" :key="item.id" class="tableitem point">
								<div class="orderbox">
									<div class="number">Order number：{{ item.order_sn }}</div>
									<div class="statetext" v-if="item.orderstate == 1" @click="nextpay(item)">Unpaid</div>
									<div class="statetext1" v-if="item.orderstate == 2" >Cancelled</div>
									<div class="statetext1" v-if="item.orderstate == 3">Completed</div>
								</div>
								<div class="bitem" :class="{ dynamicStyles: acitemindex == index && goodslistinfoshow }">
									<transition-group name="fade">
										<div class="iteminfobox" v-for="(value, vindex) in item.order_goods" :key="value.id">
											<div class="citem" style="flex-grow: 1">{{ value.title }}</div>
											<div class="citem">{{ value.nums }}</div>
											<div class="citem">{{ value.price }}</div>
											<div class="citem" style="flex-grow: 1">{{ (value.price * value.nums).toFixed(2) }}</div>
											<div v-if="item.order_goods.length >= 2">
												<div v-if="vindex == 0">
													<div class="pullbbox" @click="itemchange(index)" v-show="acitemindex == index">
														<img src="../../assets/images/pc-user-rpullicon.png" alt="" v-show="goodslistinfoshow == false" />
														<img src="../../assets/images/pc-user-pullicon.png" alt="" v-show="goodslistinfoshow == true" />
													</div>
													<div class="pullbbox" @click="itemchange(index)" v-show="acitemindex != index">
														<img src="../../assets/images/pc-user-rpullicon.png" alt="" />
													</div>
												</div>
											</div>
										</div>
									</transition-group>
								</div>
							</div>
						</div>
					</div>
					<div class="changebox">
						<MyPagination v-model="currentPage" @change="getorder" :show-page-size="1" :total-items="totalItems" />
					</div>
				</div>
			</div>
		</div>
		<!--修改资料 -->
		<van-overlay :show="headimgShow">
			<div class="updatabox">
				<div class="title">
					Modify data
					<img @click="headimgShow = !headimgShow" src="../../assets/images/overlay-close.png" alt="" />
				</div>
				<div class="headimgbox">
					<img :src="imgbaseUrl + updataimg" alt="" />
					<div class="headmsk">
						Avatar <br />
						modification
					</div>
					<input class="point" type="file" ref="uploader" @change="handleAvatarSuccess" id="upload" style="height: 1.3rem; opacity: 0; position: absolute; width: 1.3rem" accept="image/*" />
				</div>
				<div class="updatabtn point" @click="updatahead">Definite modification</div>
			</div>
		</van-overlay>
		<!--修改密码 -->
		<van-overlay :show="passwordShow">
			<div class="updatabox">
				<div class="title">
					Modify data
					<img @click="passwordShow = !passwordShow" src="../../assets/images/overlay-close.png" alt="" />
				</div>
				<div class="passwordcontent">
					<p>Old password</p>
					<input type="password" v-model="oldpassvalue" />
					<p>New password</p>
					<input type="password" v-model="newpassvalue" />
					<p>Confirm New password</p>
					<input type="password" v-model="enterpassvalue" />
				</div>
				<div class="updatabtn point" @click="updatapass">Definite modification</div>
			</div>
		</van-overlay>
		<!--修改邮箱 -->
		<van-overlay :show="changeemailShow">
			<div class="updatabox">
				<div class="title">
					Modify data
					<img @click="changeemailShow = !changeemailShow" src="../../assets/images/overlay-close.png" alt="" />
				</div>
				<div class="emailbox">
					<p>Modify e-mail address</p>
					<div class="wrap-box flex" style="margin-bottom: 0.1rem">
						<div class="input-box">
							<input v-model="newemailvalue" placeholder="input" />
						</div>
						<div class="input-btn-box flex-c">
							<div class="h-full flex-c point" @click="checkEmailOnClick">
								<div v-if="isCheckIng" class="check-img rotate360">
									<img class="w-h-full" src="@/assets/images/check-loading.png" alt="" />
								</div>
								<div v-else-if="isCheckOver" class="check-img">
									<img class="w-h-full" src="@/assets/images/check-over.png" alt="" />
								</div>
								<div v-else class="flex-c w-h-100">Verify email address</div>
							</div>
						</div>
					</div>
				</div>
				<div class="updatabtn point" @click="updataemail">Definite modification</div>
			</div>
		</van-overlay>
		<!-- 继续支付 -->
		<van-overlay :show="payOverlay">
			<div class="float-box absolute-c">
				<div class="top">
					<iframe class="iframe" :src="iframeUrl" width="100%" height="100%"></iframe>
				</div>
				<div class="bottom">
					<div @click="cancelOnClick" class="btn flex-c point">Cancel payment</div>
				</div>
				<div @click="payOverlay = false" class="pay-close-box point"></div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import { getorderApi, UploadApi, SetprofileApi, SetpassApi, checkEmailApi, checkEmailTimeApi, UPdataEmailApi, orderCancelApi } from '../../network/api';
	import { mapState, mapActions, mapMutations } from 'vuex';
	export default {
		data() {
			return {
				isShowFloat: false, //下拉状态
				selectList: [
					{
						label: 'All',
						value: '0'
					},
					{
						label: 'Unpaid',
						value: '1'
					},
					{
						label: 'Cancelled',
						value: '2'
					},
					{
						label: 'Completed',
						value: '3'
					}
				],
				curtype: '1',
				value: '1',
				goodslistinfoshow: false,
				orderlist: [],
				acitemindex: null,
				headimgShow: false, //修改头像资料
				passwordShow: false, //修改资料
				updataimg: '',
				oldpassvalue: '',
				newpassvalue: '',
				enterpassvalue: '',
				isCheckIng: false,
				isCheckOver: false,

				changeemailShow: false,
				newemailvalue: '',
				newemailtoken: '',
				currentPage: 1,
				totalItems: 1,

				payOverlay: false,
				iframeUrl: '',
				order_sn:''
			};
		},
		created() {
			this.getorder();
		},
		computed: {
			parseSelectList() {
				const array = this.selectList.map((item) => {
					return {
						label: item?.label || item,
						value: item?.value || item
					};
				});
				return array;
			},
			...mapState(['userInfo', 'imgbaseUrl'])
		},
		methods: {
			...mapMutations(['setCarList']),
			async cancelOnClick() {
				const params = {
					order_sn: this.order_sn
				};
				this.payOverlay = false;
				await orderCancelApi(params);
				this.payOverlay = false;
			},
			nextpay(v) {
				console.log(v);
				this.order_sn=v.order_sn
				this.payOverlay = true;
				this.iframeUrl = v.pay_url;
			},
			updataemail() {
				if (this.newemailvalue == '') {
					this.$message.error('Please enter a new e-mail address');
				} else {
					const params = {
						newemail: this.newemailvalue,
						auth: this.newemailtoken
					};
					console.log(params);
					UPdataEmailApi(params).then((res) => {
						// console.log(res);
						if (res.code == 1) {
							this.changeemailShow = false;
							this.$message.success('Change email address successfully');
							this.ReFreshUserInfo();
						}
					});
				}
			},
			async checkEmailOnClick() {
				console.log('checkEmailOnClick');
				if (this.newemailvalue == '') {
					this.$message.error('Please enter a new e-mail address');
				} else {
					const params = {
						email: this.newemailvalue,
						type: 3
					};
					const res = await checkEmailApi(params);
					if (res.code === 1) {
						this.$message.success('The verification email was successfully sent. Please go to your email to check');
						this.isCheckIng = true;
						this.Timer = setInterval(() => {
							this.checkEmailTimeFun();
						}, 3000);
						this.Timer2 = setTimeout(() => {
							this.$message.success('Verification timeout');
							clearInterval(this.Timer);
						}, 30000 * 30);
					}
					// console.log('checkEmailOnClick', res);
				}
			},
			async checkEmailTimeFun() {
				const params = {
					email: this.newemailvalue,
					type: 3
				};
				const res = await checkEmailTimeApi(params);
				if (res.code === 1) {
					this.$message.success('Verification successful');
					this.isCheckIng = false;
					this.isCheckOver = true;
					clearTimeout(this.Timer2);
					clearInterval(this.Timer);
					this.newemailtoken = res.data.token;
				}
				// console.log('checkEmailOnClick', res);
			},
			async updatapass() {
				if ((!this.oldpassvalue, !this.newpassvalue, !this.enterpassvalue)) {
					this.$message.error('Please enter content');
				} else {
					if (this.newpassvalue != this.enterpassvalue) {
						this.$message.error('Inconsistency between old and new passwords');
					} else {
						let params = {
							password: this.oldpassvalue,
							newpassword: this.enterpassvalue
						};
						let res = await SetpassApi(params);
						if (res.code == 1) {
							this.$message.success('Password changed successfully');
							this.loginOut();
							this.$router.push('/home');
						}
					}
				}
			},
			updatahead() {
				let params = {
					avatar: this.updataimg
				};
				SetprofileApi(params).then((res) => {
					if (res.code == 1) {
						this.headimgShow = false;
						this.ReFreshUserInfo();
					}
					(r) => {
						if (r.code != 1) {
							this.$message.error(r.msg);
						}
					};
				});
			},
			async handleAvatarSuccess(e) {
				const params = new FormData();
				const file = e.target.files[0];
				params.append('file', file);
				const res = await UploadApi(params);
				this.updataimg = res.data.url;
			},
			updataclick() {
				this.headimgShow = !this.headimgShow;
				this.updataimg = this.userInfo.avatar;
			},
			...mapActions(['loginOut', 'ReFreshUserInfo']),
			loginOutOnClick() {
				this.loginOut();
				this.$router.push('/home');
				let params = [];
				this.setCarList(params);
			},
			itemchange(v) {
				this.goodslistinfoshow = !this.goodslistinfoshow;
				this.acitemindex = v;
			},
			getorder() {
				let params = {
					page: this.currentPage,
					orderstate: this.curtype
				};
				getorderApi(params).then((res) => {
					this.totalItems = res?.data?.TotalItems;
					this.orderlist = res.data.Items;
				});
			},
			changeSelect(v) {
				this.value = v.value;
				this.curtype = v.value;
				if (v.value == 0) {
					this.curtype = null;
				}
				this.getorder();
				// console.log(this.curtype);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.headimg {
		width: 100%;
		height: 3rem;
		background: url('../../assets/images/pc-user-headimg.png') no-repeat;
		background-size: 100% 100%;
	}
	.userinfobox {
		width: 100%;
		height: 1.8rem;
		background: #fff;
		box-shadow: 0 0.04rem 0.2rem 0 rgba(0, 0, 0, 0.15);
		.infocenter {
			width: 12.4rem;
			margin: 0 auto;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.userheadimg {
				height: 1.08rem;
				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: 0.22rem;
				color: #333333;
				img {
					width: 1.08rem;
					height: 1.08rem;
					border-radius: 50%;
					margin-right: 0.3rem;
				}
			}
			.changeinfobox {
				width: 5rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.btnbox {
					padding: 0.1rem 0.15rem;
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: 400;
					font-size: 0.18rem;
					cursor: pointer;
				}
				.b1 {
					background: #4182a4;
					color: #ffffff;
				}
				.b2 {
					background: #d9e6ed;
					color: #4182a4;
				}
			}
		}
	}
	.content {
		width: 100%;
		min-height: 50vh;
		.center {
			width: 12.5rem;
			margin: 0 auto;
			.tipsbox {
				width: 100%;
				height: 1.4rem;
				display: flex;
				align-items: center;
				.tipsicon {
					width: 0.22rem;
					height: 0.22rem;
					margin-right: 0.1rem;
					img {
						width: 100%;
					}
				}
				.tipstext {
					font-weight: 400;
					font-size: 0.18rem;
					color: #666666;
				}
			}
			.listbox {
				margin-top: 0.2rem;
				width: 100%;
				// height: 5rem;
				border: 0.01rem solid #4182a4;
				margin-bottom: 1.5rem;
				.listhead {
					width: 100%;
					height: 0.98rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 0.54rem;
					box-sizing: border-box;
					border-bottom: 0.01rem solid #4182a4;
					.headtext {
						font-weight: 500;
						font-size: 0.24rem;
						color: #333333;
					}
					.searchbox {
						.select-box {
							width: 2.12rem;
							height: 0.4rem;
							background: #fff;
							border: 0.01rem solid #4182a4;
							position: relative;
							.input-value {
								width: 100%;
								height: 100%;
								line-height: 0.4rem;
								padding-left: 0.2rem;
								box-sizing: border-box;
								font-size: 0.16rem;
								font-weight: 400;
								color: #999999;
								overflow: hidden;
								white-space: nowrap;
							}
							.row {
								position: absolute;
								top: 0.1rem;
								right: 0.2rem;
								img {
									width: 0.26rem;
									height: 0.24rem;
								}
							}

							.float-box1 {
								position: absolute;
								left: -0.01rem;
								background: #fff;
								z-index: 99;
								border: 0.01rem solid #4182a4;
								// border-top: 0;
								.float-box-item {
									width: 2.12rem;
									height: 0.34rem;
									line-height: 0.34rem;
									padding: 0 0.2rem;
									box-sizing: border-box;
									font-size: 0.16rem;
									font-weight: 400;
									color: #999999;
									white-space: nowrap;
									overflow: hidden;
								}
								.float-box-item:hover {
									background: #eaf4f9;
								}
							}
						}
					}
				}
				.table-box {
					.table-header {
						width: 100%;
						height: 0.56rem;
						display: flex;
						justify-content: center;
						background: #eaf4f9;
						align-items: center;
						font-weight: 500;
						font-size: 0.2rem;
						color: #333333;
						div {
							width: 2rem;
							text-align: center;
						}
					}
					.tableitem {
						position: relative;
						.fade-enter-active,
						.fade-leave-active {
							transition: opacity 0.5s ease;
						}
						.fade-enter,
						.fade-leave-to {
							opacity: 0;
						}
						.orderbox {
							height: 0.8rem;
							background: #4182a4;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
							box-sizing: border-box;
							padding: 0 0.54rem;
							.number {
								// font-weight: 500;
								font-size: 0.26rem;
								color: #ffffff;
							}
							.statetext {
								font-weight: 400;
								font-size: 0.2rem;
								color: #ff4e4e;
								padding: 0.07rem 0.2rem;
								background: #fff;
							}
							.statetext1 {
								font-weight: 400;
								font-size: 0.2rem;
								color: #fff;
							}
						}

						.bitem {
							overflow: hidden;
							height: 0.6rem;
						}
						.dynamicStyles {
							height: 1.8rem;
							overflow-y: scroll;
						}
						.iteminfobox {
							width: 100%;
							height: 0.58rem;
							background: #ffffff;
							border-bottom: 0.01rem solid rgba(65, 130, 164, 0.4);
							display: flex;
							align-items: center;
							.citem {
								width: 2rem;
								text-align: center;
							}
						}
						.pullbbox {
							position: absolute;
							right: 0.5rem;
							top: 1rem;
							img {
								width: 0.22rem;
								height: 0.22rem;
							}
						}
					}
				}
				.changebox {
					width: 100%;
					height: 1rem;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
	.updatabox {
		width: 5rem;
		// height: 5.86rem;
		border-radius: 0.1rem;
		background: #ffffff;
		box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.2), 0 -0.01rem 0.04rem 0 rgba(0, 0, 0, 0.2);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		.title {
			width: 100%;
			height: 0.64rem;
			background: #7bb9d9;
			border-radius: 0.1rem 0.1rem 0 0;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 500;
			font-size: 0.24rem;
			color: #ffffff;
			position: relative;
			img {
				position: absolute;
				right: 0.3rem;
				width: 0.28rem;
				cursor: pointer;
			}
		}
		.headimgbox {
			width: 100%;
			height: 2rem;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 1.3rem;
				height: 1.3rem;
				border-radius: 50%;
				position: relative;
			}
			.headmsk {
				width: 1.3rem;
				height: 1.3rem;
				border-radius: 50%;
				position: absolute;
				background: #000000;
				opacity: 0.6;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 400;
				font-size: 0.12rem;
				color: #ffffff;
				text-align: center;
				line-height: 0.2rem;
			}
		}
		.updatabtn {
			width: 4.4rem;
			height: 0.44rem;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 500;
			font-size: 0.14rem;
			color: #ffffff;
			margin: 0 auto;
			background: #4182a4;
			margin-bottom: 0.4rem;
			margin-top: 0.2rem;
		}
		.passwordcontent {
			width: 4.4rem;
			margin: 0 auto;
			p {
				font-weight: 400;
				font-size: 0.16rem;
				color: #333333;
				margin: 0.12rem 0;
			}
			input {
				width: 100%;
				height: 0.44rem;
				border: 0.01rem solid #b3cddb;
			}
			.wrap-box {
				height: 100%;
				.input-box {
					width: 50%;
					margin-right: 0.2rem;
				}
				.input-item {
					flex-grow: 1;
				}
				.input-btn-box {
					width: 1.96rem;
					height: 0.44rem;
					flex-shrink: 0;
					background: #4182a4;
					font-weight: 500;
					font-size: 0.14rem;
					color: #ffffff;
					.check-img {
						width: 0.28rem;
						height: 0.28rem;
					}
				}
			}
		}
		.emailbox {
			width: 4.4rem;
			margin: 0 auto;
			p {
				font-weight: 400;
				font-size: 0.16rem;
				color: #333333;
				margin: 0.12rem 0;
			}
			.wrap-box {
				height: 100%;
				.input-box {
					width: 60%;
					input {
						width: 100%;
						height: 0.42rem;
						border: 0.01rem solid #b3cddb;
						padding: 0;
					}
				}

				.input-btn-box {
					width: 1.96rem;
					height: 0.44rem;
					flex-shrink: 0;
					background: #4182a4;
					font-weight: 500;
					font-size: 0.14rem;
					color: #ffffff;
					.check-img {
						width: 0.28rem;
						height: 0.28rem;
					}
				}
			}
		}

		// .center {
		// 	width: 4.4rem;
		// 	margin: 0 auto;
		// 	.passwordbox{
		//          margin-bottom: 0.2rem;
		// 		 p{
		// 			margin: 0 0 0.12rem 0;
		// 		 }
		// 	}
		// }
	}
	.float-box {
		width: 70vw;
		height: 70vh;
		background: #ffffff;
		border-radius: 0.3rem;
		.top {
			width: 100%;
			height: 90%;
			padding: 0.2rem;
			box-sizing: border-box;
			.iframe {
				body {
					margin: 0;
				}
			}
		}
		.bottom {
			padding: 0 0.3rem;
			.btn {
				width: 100%;
				height: 0.44rem;
				background: rgba(65, 130, 164, 0.2);
				font-weight: 500;
				font-size: 0.16rem;
				color: #4182a4;
			}
		}
		.pay-close-box {
			position: absolute;
			top: -0.2rem;
			right: -0.6rem;
			width: 0.48rem;
			height: 0.48rem;
			background: url('~@/assets/images/pay-close.png') no-repeat;
			background-size: 100% 100%;
		}
	}
</style>
